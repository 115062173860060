@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #524880;

  background-color: #4c458b;

  @media screen and (max-width: 767px) {
    background-color: #FFFFFF;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.reset-button {
  background: #524686 !important;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.16px;
  width: 100%;
  height: 48px;
  border: 0px;
}


.container-fluid {
  height: 100vh;
}

.reset-container-fluid {
  height: 100vh;

  .reset-row {
    height: 100%;
  }

}

.reset-container-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    align-items: start;
    padding-top: 20px;
  }

  .reset-container {
    width: 480px;

    .logo {
      width: 160px;
      display: block;
      margin: 0px auto 26px auto;

      @media (max-width: 767px) {
        width: 90px;
        margin-bottom: 17px;
      }
      
    }

    @media (max-width: 767px) {
      .logo-desktop {
        display: none;
      }
    }

    @media (min-width: 768px) {
      .logo-mobile {
        display: none;
      }
    }


    .form-container {

      background: #FFFFFF;
      border-radius: 24px;
      padding: 35px 40px 52px 40px;


      h1 {
        font-weight: 500;
        font-size: 32px;
        color: #252C3B;
        letter-spacing: -0.32px;
        text-align: center;

        @media (max-width: 767px) {
          font-size: 26px;
        }

      }

      p.tagline {
        font-size: 15px;
        color: #6F747E;
        letter-spacing: -0.15px;
        text-align: center;
        line-height: 22px;
        margin-bottom: 0px;
      }

      form {
        margin-top: 35px;

        .form-group {

          margin-bottom: 20px;

          label {
            font-weight: 400;
            font-size: 15px;
            color: #6F747E;
            letter-spacing: -0.15px;
            line-height: 22px;
            margin-bottom: 10px;
          }

          .wrap-input {

            position: relative;

            img {
              position: absolute;
              top: 17.4px;
              right: 17.4px;
              cursor: pointer;
            }

            img.hide-eye-icon {
              top: 13px;
            }

            .form-control {
              background: rgba(216, 216, 216, 0.3);
              border: 1px solid rgba(111, 116, 126, 0.3);
              border-radius: 6px;
              height: 48px;
              font-size: 18px;
              color: #252C3B;
              letter-spacing: -0.18px;

              
            }

            .form-control.password-type {
              padding-right: 45px;
            }
  
            .form-control:focus {
              box-shadow: none;
            }

          }
          
          .message {
            font-size: 12px;
            color: #6F747E;
            letter-spacing: -0.12px;
            line-height: 21px;
            margin-top: 2px;
            margin-bottom: 0px;
          }

          .message.error-message {
            color: #FF5252;
          }


        }

        .last-form-group {
          margin-bottom: 32px;

          @media (max-width: 767px) {
            margin-bottom: 24px;
          }

        }

      }

      @media (max-width: 767px) {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0px;
      }


    }

    .form-container.link-expired-container {
  
      img {
        display: block;
        margin: 0px auto 13px auto;
      }

      > p {
        font-size: 15px;
        color: #6F747E;
        letter-spacing: -0.15px;
        text-align: center;
        line-height: 22px;
        margin-bottom: 0px;
      }

      form {
        margin-top: 26.7px;

        .email-group {
          margin-bottom: 26.3px;
        }
      }
    }


    .confirmation-container {
      background: #FFFFFF;
      border-radius: 24px;
      padding: 46px 15px 72px 15px;
      text-align: center;

      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }

      h2 {
        font-weight: 500;
        font-size: 28px;
        color: #252C3B;
        letter-spacing: -0.28px;
        margin-top: 24px;
        margin-bottom: 21px;
      }

      p {
        font-size: 15px;
        color: #6F747E;
        letter-spacing: -0.15px;
        text-align: center;
        line-height: 22px;
        margin: 0px;
      }
    }

    .confirmation-container-two {
      background: #FFFFFF;
      border-radius: 24px;
      padding: 46px 15px 51px 15px;
      text-align: center;

      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }

      h2 {
        font-weight: 500;
        font-size: 28px;
        color: #252C3B;
        letter-spacing: -0.28px;
        margin-top: 24px;
        margin-bottom: 21px;
      }

      p {
        font-size: 15px;
        color: #6F747E;
        letter-spacing: -0.15px;
        text-align: center;
        line-height: 22px;
        margin: 0px;

        .email-highlight {
          color: #524686;
          font-weight: 700;
        }

        .resend-highlight {
          color: #524686;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
        }

        &:last-child {
          margin-top: 36px;

          @media screen and (max-width: 767px) {
            margin-top: 115px;
          }
        }
      }

    }

    .resetting-container {
        
      background: #FFFFFF;
      border-radius: 24px;
      padding: 74px 15px 118px 15px;
      text-align: center;

      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }

      // .circle-loading {
      //   width: 60px;
      //   height: 60px;
      //   margin: 0px auto 42px auto;
      //   background-color: #FFFFFF;
      //   border-radius: 50%;
      //   border: 4px solid #E9E5FC;
      // }

      .circle-loading-2 {
       
        margin: 0px auto 42px auto;
       
      }

      h2 {
        font-weight: 500;
        font-size: 20px;
        color: #252C3B;
        letter-spacing: -0.2px;
        margin: 0px;
      }

    }


  }

}


.modal-error {

  width: 313px;

  @media screen and (max-width: 575px) {
   
    margin: 8px auto 8px auto;
    
  }

  .modal-content {  
    background: #FFFFFF;
    border-radius: 16px;

    .modal-body {
      padding: 38px 0px 46px 0px;
      .modal-container {
        text-align: center;

        > img {
          margin-bottom: 20px;
        }

        h3 {
          font-weight: 500;
          font-size: 22px;
          color: #252C3B;
          letter-spacing: -0.22px;
          text-align: center;
          margin-bottom: 15px;
        }
        
        p {
          font-size: 15px;
          color: #6F747E;
          letter-spacing: -0.15px;
          text-align: center;
          line-height: 22px;
          margin-bottom: 0px;
        }

        p.modal-close {
          margin-top: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            color: #524686;
            font-weight: 700;
            margin-left: 8px;
          }

        }

      }
    }

  }

}



/* Loader 2 */
.loader-2 {
  display: block;
	height: 62px;
	width: 62px;
	-webkit-animation: loader-2-1 3s linear infinite;
	        animation: loader-2-1 3s linear infinite;
  border: 2px solid #E9E5FC;
  border-radius: 50%;
}
@-webkit-keyframes loader-2-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 60px;
	width: 60px;
	clip: rect(50px, 60px, 60px, 0);
	-webkit-animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-2-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 60px;
	width: 60px;
	border: 3px solid transparent;
	border-top: 3px solid #FFF;
	border-radius: 50%;
	-webkit-animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-2-3 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-3 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 60px;
	width: 60px;
	// border: 3px solid rgba(255, 255, 255, .5);
  border: 3px solid #524686;
	border-radius: 50%;
}









